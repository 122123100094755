import React, { Suspense, useState } from 'react';
import { observer } from 'mobx-react';
import { CamperSearchParams, Overlay } from '@ibe/components';
import { ConfigModel } from '@ibe/services';
import initI18next from '../Translations/setup';
import { useMount } from 'react-use';
import { boot, extendedConfigContext } from '../Config/config';
import SearchWidgetContent from '../Components/SearchWidgetContent';
import '../Theme/Styles/DER/dto/widgets/searchWidget.scss';
import theme from '../Theme/Styles/DER/dto/theme';
import { ThemeProvider } from '@emotion/react';
import CamperLoading from '../Components/CamperLoading';
import { CmbConfigModel } from '../Config/CmbConfigModel';
import getTagManagerLoaded from '../Tracking/TagManagerPromise';

const SearchWidget = observer(function SearchWidget({
  rerouteUrl,
  searchParams,
  configOverrides,
  cmbConfig,
  affiliateNumber
}: {
  cmbConfig: CmbConfigModel;
  rerouteUrl?: string;
  searchParams?: Partial<CamperSearchParams>;
  configOverrides?: ConfigModel;
  affiliateNumber?: string;
}) {
  const [bootstrappingFinished, setBootstrappingFinished] = useState<boolean>(false);

  useMount(async () => {
    await getTagManagerLoaded();

    await initI18next(cmbConfig.baseUrl, cmbConfig.version);
    await boot(configOverrides, false, cmbConfig, true, affiliateNumber);
    setBootstrappingFinished(true);
  });

  return bootstrappingFinished ? (
    <ThemeProvider theme={theme}>
      <Suspense
        fallback={
          <div className="transparent-overlay" style={{ height: '95px', position: 'relative' }}>
            <Overlay fullOpacity customSpinner={<CamperLoading />} />
          </div>
        }
      >
        <extendedConfigContext.Provider value={cmbConfig}>
          <SearchWidgetContent rerouteUrl={rerouteUrl} searchParams={searchParams} />
        </extendedConfigContext.Provider>
      </Suspense>
    </ThemeProvider>
  ) : (
    <div className="transparent-overlay" style={{ height: '95px', position: 'relative' }}>
      <Overlay fullOpacity customSpinner={<CamperLoading />} />
    </div>
  );
});

export default SearchWidget;
